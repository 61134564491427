<template>
  <v-container>
    <v-card>
      <v-card-title>
        Cloud Consult
      </v-card-title>
      <v-card-text v-if="errors.notAuthorized">
        Vous ne disposez pas des droits nécessaire à l'utilisation de YodaCloud Consult.
      </v-card-text>
      <v-card-text v-else>
        <y-company-select v-model="selectedCompany" v-if="isUserRoot"/>

        <v-select :loading="loading.tables" :disabled="!wakandaTables" :error="errors.fetchingTables" :error-messages="errors.fetchingTables ? ['Impossible de récuperer le modèle du cloud de la société sélectionnée'] : []" :items="wakandaTables" v-model="selectedTable" label="Table"/>
        <v-select :disabled="!selectedTable" :items="requestTypes" v-model="selectedRequestType" label="Type de demande"/>

        <div v-if="selectedTable">
          <div v-if="selectedRequestType === 'byId'">
            <v-text-field v-model="requestedIdentifier" label="UUID"/>
          </div>
          <div v-if="selectedRequestType === 'page'">
            <v-text-field v-model="requestQueryFilter" label="Requête"/>
          </div>
        </div>

        <v-card-actions>
          <v-spacer/><v-btn :disabled="!readyToRequest" color="primary" @click="requestHasBeenClicked" :loading="loading.requestingWakanda">Envoyer la requête</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <v-alert v-if="errors.requestingWakanda" type="error" dismissible>
      Impossible de réaliser la requête.
    </v-alert>

    <v-card v-if="requestResult && !loading.requestingWakanda">
      <v-card-text>
        <pre>{{requestResult}}</pre>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import {mapState} from "vuex";

  export default {
    name: 'HomeView',

    components: {

    },

    data: () => ({
      errors: {
        notAuthorized: false,
        fetchingTables: false,
        requestingWakanda: false
      },
      loading: {
        tables: false,
        requestingWakanda: false
      },
      selectedCompany: null,
      selectedRequestType: 'byId',
      selectedTable: null,

      requestedIdentifier: "",
      requestQueryFilter: "",

      requestTypes: [
        {text: 'Selection par identifiant', value: 'byId'},
        {text: 'Requete par critère', value: 'page'},
        // {text: 'Requete native brute', value: 'raw'}
      ],
      wakandaTables: null,

      requestResult: null
    }),
    mounted() {
      if (this.isUserCompany) {
        this.selectedCompany = {uuid: this.getUserCompanyUuid()}
      } else if (!this.isUserRoot) {
        this.errors.notAuthorized;
      }
    },
    watch: {
      selectedCompany(newVal) {
        if (newVal && newVal.uuid) {
          this.refreshAvailableTables()
        } else {
          this.wakandaTables = null;
          this.selectedTable = null;
        }
      }
    },
    computed: {
      ...mapState("auth", ['userAuthorisations']),
      readyToRequest() {
        return this.selectedCompany && this.selectedTable && this.selectedRequestType
      },
      isUserRoot() {
        if (Array.isArray(this.userAuthorisations)) {
          for (let i = 0; i < this.userAuthorisations.length; i++) {
            if (this.userAuthorisations[i].authorization.role === "root") {
              return true;
            }
          }
          return false;
        } else return false;
      },
      isUserCompany() {
        if (Array.isArray(this.userAuthorisations)) {
          for (let i = 0; i < this.userAuthorisations.length; i++) {
            if (this.userAuthorisations[i].authorization.role === "company") {
              return true;
            }
          }
          return false;
        } else return false;
      },
      getUserCompanyUuid() {
        if (Array.isArray(this.userAuthorisations)) {
          for (let i = 0; i < this.userAuthorisations.length; i++) {
            if (this.userAuthorisations[i].authorization.role === "company") {
              return this.userAuthorisations[i].scope.companyUuid;
            }
          }
          return null;
        } else return null;
      },
    },
    methods: {
      refreshAvailableTables() {
        this.loading.tables = true;
        this.errors.fetchingTables = false;
        this.$http.get(this.$config.apiUrl+'/v1/companies/'+this.selectedCompany.uuid+'/native/$catalog')
            .then(r => {
              this.wakandaTables = r.data.dataClasses.map(t => t.name);
              this.selectedTable = this.wakandaTables[0];
            })
            .catch(e => {
              console.log(e)
              this.errors.fetchingTables = true;
              this.wakandaTables = null;
            })
            .finally(() => this.loading.tables = false)
      },
      requestHasBeenClicked() {
        this.loading.requestingWakanda = true;
        this.errors.requestingWakanda = false;
        let url = this.$config.apiUrl+'/v1/companies/'+this.selectedCompany.uuid+'/native/'+this.selectedTable;
        switch (this.selectedRequestType) {
          case 'byId':
            url += "/"+this.requestedIdentifier;
            break;
          case 'page':
            url += "?query="+this.requestQueryFilter;
            break;
        }
        this.$http.get(url)
            .then(r => {
              this.requestResult = r.data;
            })
            .catch(e => {
              console.log(e)
              this.errors.requestingWakanda = true;
              this.requestResult = null;
            })
            .finally(() => this.loading.requestingWakanda = false)
      }
    }
  }
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/vue-moment'
import config from './config'
import axios from 'axios'
import './plugins/vue-moment'
import store from './store'

import YfxCloudAuth from '@yodatech/vue-yfx-cloud-auth'
import YfxLicense from '@yodatech/vue-yfx-license'

Vue.use(YfxCloudAuth, {serviceName: "cloud-consult", store: store, axios: axios, ...config.authConfig})
Vue.use(YfxLicense, {axios: axios})

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$config = config;

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
